import Vue from 'vue';
import App from './App';
import router from './router';
import { store } from './store';
import { refreshJwt } from '@/assets/js/utils';
import * as Sentry from '@sentry/vue';

const isProd = process.env.BUILD_ENV === 'production';
if (isProd) {
    Sentry.init({
        Vue,
        dsn: 'https://119a3de95a7547a08480bb8ef4712c2e@o4505430079438848.ingest.sentry.io/4505515648417792',
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['https://app.qfact.nl', 'https://api.qfact.nl/graphql'],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    console.log('Sentry initialized');
}

import VueApollo from 'vue-apollo';
import jwtDecode from 'jwt-decode';
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';
import Axios from 'axios';

import QfactDesignSystem from '@/components/qds/components';
import FlexboxGrid from 'flexboxgrid/dist/flexboxgrid.css';
// Object.definePrototype(Vue.prototype, '$uxTour', { value: uxTour });

import VueI18n from 'vue-i18n';
import { nl } from './assets/js/translations';

import Ability from './assets/js/ability';
import { ProjectAbility } from './pages/app/project/utils';

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;

Vue.use(QfactDesignSystem);
Vue.use(VueApollo);

Vue.use(VueI18n);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/nl';

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

Vue.use(ElementUI, { locale });

const i18n = new VueI18n({
    locale: 'nl',
    messages: { nl },
    silentTranslationWarn: true,
});

const httpLink = new HttpLink({ uri: process.env.GRAPHQL_URI });

const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('jwt');

    if (token) {
        const tokenPayload = jwtDecode(token);
        const { iat, exp } = tokenPayload;
        const now = Date.now() / 1000;
        const halfJwtDuration = (exp - iat) / 2;

        // Using 1 second margin for calls that get through right before token expiration
        if (exp < now + 1) {
            store.commit('resetState');
            localStorage.setItem('lastPath', router.currentRoute.fullPath);
            localStorage.setItem('lastLogoutTime', exp);

            if (tokenPayload.data.authMeta.sso) {
                const { slug } = tokenPayload.data.authMeta;

                return router.push(`/sso/${slug}`);
            } else {
                store.commit('notify', { type: 'info', message: 'U bent uitgelogd vanwege inactiviteit.' });
                return router.push('/login');
            }
        } else {
            // refrsh JWT if token has expired for more than 50% of it's valid duration
            if (exp - halfJwtDuration < now && operation.operationName.toLowerCase() !== 'refresh') refreshJwt(vm);

            operation.setContext({
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
            });
        }
    }

    return forward(operation);
});

// custom directive for focussing elements
Vue.directive('focus', {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
        // Focus the element
        el.focus();
    },
});

Vue.directive('can', {
    inserted(el, binding, vnode, old) {
        const partials = binding.value.split('__');

        if (partials.length !== 2) {
            console.error(
                `permission directive 'can' value ${binding.value} should have pattern {entity}__{action}. for example: Project__create'`
            );
            vnode.elm.parentElement.removeChild(vnode.elm);
            return;
        }

        const entity = partials[0];
        const action = partials[1];

        const ability = vnode.context.ability.get();

        if (!ability.can(action, entity)) {
            vnode.elm.parentElement.removeChild(vnode.elm);
        }
    },
});

Vue.directive('project-can', {
    inserted(el, binding, vnode, old) {
        const projectAbility = vnode.context.projectAbility;

        if (!projectAbility.can(binding.value)) {
            vnode.elm.parentElement.removeChild(vnode.elm);
        }
    },
});

const cache = new InMemoryCache();
const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
});

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});

// Check organistion modules and user permissions
Vue.mixin({
    methods: {
        __MOD: function (module) {
            const orgProducts = store.getters.getCurrentOrganisation.products || [];
            return orgProducts.map((product) => product.enabled && product.slug).includes(module);
        },
        __CAN: function (permission, object) {
            const tokenPayload = store.getters.getJwtPayload || {};
            // work in progress
            return tokenPayload.data.permissions.some(permGrp =>    permGrp.type === object && 
                                                                    permGrp.permissions.includes(permission));
        },
    },
});

Vue.prototype.ability = new Ability();
Vue.prototype.projectAbility = new ProjectAbility();

/* eslint-disable no-new */
const vm = new Vue({
    el: '#app',
    router,
    store,
    apolloProvider,
    i18n,
    render: (h) => h(App),
});
